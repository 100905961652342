import { defineStore } from 'pinia'
import { saveLog } from '@/api/log'
import { useOrderStore } from '@/stores/orderStore'

export const useLogStore = defineStore('log', {
  actions: {
    async saveLog(data) {
      try {
        const response = await saveLog(data)
        console.log(response)
      } catch (error) {
        throw error
      }
    },
    logUserAction(orderMessage, orderRemark = '') {
      const orderStore = useOrderStore()
      const logData = {
        orderId: orderStore.orderId,
        orderName: '点餐页面操作',
        orderMessage,
        orderRemark
      }
      // 异步存储日志，不阻塞主流程
      setTimeout(() => {
        this.saveLog(logData)
      }, 0)
    }
  }
})