import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-201c6fa6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "step-content"
};
const _hoisted_2 = {
  class: "step-title"
};
export default {
  __name: 'index',
  props: {
    activeStep: {
      type: Number,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const steps = ['选择餐厅', '选择餐点', '等待取餐码', '到店取餐'];
    const getStepClass = index => {
      if (index < props.activeStep) {
        return 'finish';
      } else if (index === props.activeStep) {
        return 'process';
      } else {
        return 'wait';
      }
    };
    return (_ctx, _cache) => {
      const _component_el_step = _resolveComponent("el-step");
      const _component_el_steps = _resolveComponent("el-steps");
      return _openBlock(), _createBlock(_component_el_steps, {
        active: __props.activeStep,
        "finish-status": "success",
        "align-center": ""
      }, {
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(steps, (title, index) => {
          return _createVNode(_component_el_step, {
            key: index
          }, {
            icon: _withCtx(() => [_createElementVNode("div", {
              class: _normalizeClass(['step-icon', getStepClass(index)])
            }, _toDisplayString(index + 1), 3)]),
            title: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(title), 1)])]),
            _: 2
          }, 1024);
        }), 64))]),
        _: 1
      }, 8, ["active"]);
    };
  }
};