import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1a431f5a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "customization-dialog"
};
const _hoisted_2 = {
  class: "customization-header"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  key: 0,
  class: "customization-options"
};
const _hoisted_5 = {
  class: "customization-footer"
};
import { ref, watch, onMounted, computed } from 'vue';
export default {
  __name: 'main-dish',
  props: {
    item: Object
  },
  emits: ['close', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const filteredRounds = computed(() => {
      return props.item?.rounds?.filter(round => round.type === 2) || [];
    });
    const emit = __emit;
    const selectedOptions = ref({});
    const initializeOptions = item => {
      if (item) {
        selectedOptions.value = {};
        item.rounds.forEach(round => {
          if (round.type === 2) {
            selectedOptions.value[round.id] = round.items.filter(option => option.isDefault === 'Y').map(option => String(option.id));
          }
        });
      }
    };
    watch(props.item, newItem => {
      initializeOptions(newItem);
    });
    onMounted(() => {
      initializeOptions(props.item);
    });
    const confirm = () => {
      emit('confirm', {
        item: props.item,
        selectedOptions: selectedOptions.value
      });
      emit('close');
    };
    const close = () => {
      emit('close');
    };
    return (_ctx, _cache) => {
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createBlock(_Transition, {
        name: "slide-up"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "overlay",
          onClick: _withModifiers(close, ["self"])
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          src: __props.item.mainImageUrl,
          alt: "Selected Item Image"
        }, null, 8, _hoisted_3), _createElementVNode("h3", null, _toDisplayString(__props.item.itemSkuName), 1)]), filteredRounds.value.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredRounds.value, round => {
          return _openBlock(), _createElementBlock("div", {
            key: round.id
          }, [_createElementVNode("h4", null, _toDisplayString(round.roundName), 1), _createVNode(_component_el_checkbox_group, {
            modelValue: selectedOptions.value[round.id],
            "onUpdate:modelValue": $event => selectedOptions.value[round.id] = $event
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(round.items, option => {
              return _openBlock(), _createBlock(_component_el_checkbox, {
                key: option.id,
                label: String(option.id)
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(option.itemSkuName), 1)]),
                _: 2
              }, 1032, ["label"]);
            }), 128))]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue"])]);
        }), 128))])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "success",
          round: "",
          onClick: confirm
        }, {
          default: _withCtx(() => [_createTextVNode("选好了")]),
          _: 1
        })])])])]),
        _: 1
      });
    };
  }
};