import axios from 'axios'
import errorCode from '@/utils/errorCode'
import { tansParams } from '@/utils/params'
import { ElNotification, ElMessageBox, ElMessage } from 'element-plus'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 20000
})

// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    if (!isToken) {
        config.headers['Authorization'] = 'Bearer '
    }
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
        const requestObj = {
            url: config.url,
            data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
            time: new Date().getTime()
        }
        const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
        const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
        if (requestSize >= limitSize) {
            console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
            return config;
        }
    }
    return config
}, error => {
    console.log(error)
    if (axios.isAxiosError(error)) {
      // 判断是否是超时错误
      if (error.code === 'ECONNABORTED') {
          ElMessage({
              message: '请求超时，请重试',
              type: 'error',
              duration: 5000
          });
      }
    }
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
      return res.data
    }
    if (code === 401) {
        ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' })
        return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      // ElMessage({ message: msg, type: 'error' })
      console.log('msg1', msg)
      ElMessageBox.alert(msg, '系统提示', { type: 'error', confirmButtonText: '确定' })
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      console.log('msg2', msg)
      ElMessageBox.alert(msg, '系统提示', { type: 'warning', confirmButtonText: '确定' })
      return Promise.reject('error')
    } else if (code !== 200) {
      console.log('msg3', msg)
      ElMessageBox.alert(msg, '系统提示', { type: 'error', confirmButtonText: '确定' })
      return Promise.reject('error')
    } else {
      console.log('msg4', res.data)
      return res.data
    }
  },
  error => {
    console.log('err:' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    } else if (message.includes("timeout")) {
      message = "网络有点不稳定,请稍后再试";
    }
    ElMessage({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)



export default service
