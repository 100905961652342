import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3573684b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "product-item-container"
};
const _hoisted_2 = {
  class: "product-info"
};
const _hoisted_3 = {
  class: "product-name"
};
const _hoisted_4 = {
  class: "product-description-container"
};
const _hoisted_5 = {
  class: "product-descriptions"
};
const _hoisted_6 = {
  class: "product-description"
};
const _hoisted_7 = {
  class: "product-number"
};
import { computed } from 'vue';
import { Edit } from '@element-plus/icons-vue';
import { useOrderStore } from '@/stores/orderStore';
import { storeToRefs } from 'pinia';
import { useAnimationStore } from '@/stores/animationStore';
export default {
  __name: 'index',
  props: {
    product: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['openCustomization'],
  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const orderStore = useOrderStore();
    const {
      selectedItems
    } = storeToRefs(orderStore);
    const animationStore = useAnimationStore();
    const props = __props;
    const computedImageUrl = computed(() => {
      // const rounds = props.product.productSelectDetail?.rounds
      // if (rounds && rounds.length > 0) {
      //     const firstRound = rounds[0]
      //     const firstItem = firstRound.items?.[0]
      //     if (firstItem && firstItem.mainImageUrl) {
      //         return firstItem.mainImageUrl
      //     }
      // }
      return props.product.imageUrl;
    });
    const highlight = computed(() => animationStore.highlightUnselected);
    const getSelectedItem = round => {
      const selected = selectedItems.value[props.index]?.[round.id];
      if (selected && selected.length > 0) {
        return round.items.find(item => item.itemSkuCode === selected[0]);
      }
      const defaultItem = round.items.find(item => item.isDefault === 'Y');
      return defaultItem || round.items[0];
    };
    const getSelectedAttributes = round => {
      const selectedItem = getSelectedItem(round);
      if (!selectedItem) return '';

      // 获取所有 isDefault 为 'Y' 的属性
      const defaultAttributes = selectedItem.rounds?.flatMap(r => r.items.filter(item => item.isDefault === 'Y').map(item => item.itemSkuName)) || [];

      // 获取所有 isPush 为 0 的属性
      const pushAttributes = selectedItem.productAttributes?.flatMap(attr => attr.items.filter(opt => opt.isPush === 0).map(opt => opt.itemName)) || [];

      // 合并属性并用 '+' 分隔
      return [...defaultAttributes, ...pushAttributes].join(' + ');
    };
    const openCustomization = () => {
      orderStore.setSelectedProduct(props.product);
      emit('openCustomization', props.index);
    };
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_image, {
        class: "product-image",
        src: computedImageUrl.value,
        fit: "cover"
      }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("h3", _hoisted_3, _toDisplayString(__props.product.productName), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.product.productSelectDetail?.rounds, round => {
        return _openBlock(), _createElementBlock("div", {
          key: round.id
        }, [_createElementVNode("p", _hoisted_6, [_createTextVNode(_toDisplayString(round.quantity) + " × " + _toDisplayString(getSelectedItem(round).itemSkuName) + " ", 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getSelectedAttributes(round), attr => {
          return _openBlock(), _createElementBlock("span", {
            key: attr,
            class: "attr-info"
          }, _toDisplayString(attr), 1);
        }), 128))])]);
      }), 128)), _createElementVNode("span", _hoisted_7, _toDisplayString(__props.product.productNumber) + "份", 1)]), _createVNode(_component_el_button, {
        size: "small",
        icon: _unref(Edit),
        type: "success",
        round: "",
        onClick: openCustomization,
        class: _normalizeClass([['customization-button', {
          'attention-animation': highlight.value
        }], "customization-button"])
      }, {
        default: _withCtx(() => [_createTextVNode("选规格")]),
        _: 1
      }, 8, ["icon", "class"])])])]);
    };
  }
};