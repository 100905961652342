import { defineStore } from 'pinia'
import axios from 'axios'
import { useStoreStore } from '@/stores/store'

export const useLocationStore = defineStore('location', {
  state: () => ({
    latitude: null,
    longitude: null,
    city: '',
    selectedCity: null,
    locationError: false,
    errorMessage: ''
  }),
  actions: {
    async fetchLocation() {
      this.locationError = false 
      this.errorMessage = ''
      try {
        // 获取经纬度
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            timeout: 5000 // 设置超时时间为5秒
          });
        });
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      } catch (error) {
        console.error('Error fetching location:', error);
        if (error.code === error.PERMISSION_DENIED) {
          this.locationError = true // 设置错误状态
          this.errorMessage = '定位权限被拒绝。请手动选择您的城市。'
        } else if (error.code === error.TIMEOUT) {
          // 设置默认经纬度值
          this.latitude = 30.156431;
          this.longitude = 120.143296;
          this.errorMessage = '定位超时，已使用默认位置。'
        } else {
          this.errorMessage = '定位失败，请手动选择您的城市。'
        }
      }

      // 判断是否为测试环境
      const isTestEnv = process.env.VUE_APP_TEST_ENV === 'true';
      // if (isTestEnv) {
      //   console.log("isTestEnv: ", isTestEnv)
      //   this.latitude = 30.156431;
      //   this.longitude = 120.143296;
      // }
      
      try {
        // 逆地理编码获取城市
        const response = await axios.get(`https://restapi.amap.com/v3/geocode/regeo`, {
          params: {
            key: '8f89d4b7e99fd5a200cbab4f751915a6',
            location: `${this.longitude},${this.latitude}`
          },
          timeout: 5000
        })
        console.log("location: ", response.data)
        const city = response.data.regeocode.addressComponent.city
        this.city = (Array.isArray(city) && city.length === 0) ? '杭州' : city;
        
        // 获取附近的门店列表
        const storeStore = useStoreStore()
        await storeStore.fetchStores(this.city, '', this.longitude, this.latitude)
      } catch (error) {
        console.error('Error fetching location:', error)
        this.city = '杭州'
        this.latitude = 30.156431;
        this.longitude = 120.143296;
        this.errorMessage = '无法获取城市信息，已使用默认城市。'
      }
    },
    async setSelectedCity(city) {
      this.selectedCity = city
      const storeStore = useStoreStore()
      await storeStore.fetchStores(city.name, '', city.lng, city.lat)
    }
  }
})