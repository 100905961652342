import { defineStore } from 'pinia'
import { getProductByCode } from '@/api/product'

export const useProductStore = defineStore('product', {
  state: () => ({
    product: null
  }),
  actions: {
    async fetchProductByCode(code) {
      try {
        const response = await getProductByCode({ code })
        this.product = response.data
      } catch (error) {
        throw error
      }
    }
  }
})