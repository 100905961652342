import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ca829ee4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "customization-dialog"
};
const _hoisted_2 = {
  class: "customization-header"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "customization-options"
};
const _hoisted_5 = {
  class: "customization-footer"
};
import { ref, watch, onMounted } from 'vue';
export default {
  __name: 'index',
  props: {
    item: Object
  },
  emits: ['close', 'confirm'],
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const selectedAttributes = ref({});
    const initializeAttributes = item => {
      if (item) {
        selectedAttributes.value = {};
        item.productAttributes.forEach(attribute => {
          const defaultOption = attribute.items.find(option => option.isPush === 0) || attribute.items[0];
          selectedAttributes.value[attribute.id] = String(defaultOption.id);
        });
      }
    };
    watch(props.item, newItem => {
      initializeAttributes(newItem);
    });
    onMounted(() => {
      initializeAttributes(props.item);
    });
    const confirm = () => {
      emit('confirm', {
        item: props.item,
        selectedAttributes: selectedAttributes.value
      });
      emit('close');
    };
    const close = () => {
      emit('close');
    };
    return (_ctx, _cache) => {
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createBlock(_Transition, {
        name: "slide-up"
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "overlay",
          onClick: _withModifiers(close, ["self"])
        }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          src: __props.item.mainImageUrl,
          alt: "Selected Item Image"
        }, null, 8, _hoisted_3), _createElementVNode("h3", null, _toDisplayString(__props.item.itemSkuName), 1)]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.productAttributes, attribute => {
          return _openBlock(), _createElementBlock("div", {
            key: attribute.id
          }, [_createElementVNode("h4", null, _toDisplayString(attribute.name), 1), _createVNode(_component_el_radio_group, {
            modelValue: selectedAttributes.value[attribute.id],
            "onUpdate:modelValue": $event => selectedAttributes.value[attribute.id] = $event
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attribute.items, option => {
              return _openBlock(), _createBlock(_component_el_radio, {
                key: option.id,
                value: String(option.id)
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(option.itemName), 1)]),
                _: 2
              }, 1032, ["value"]);
            }), 128))]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue"])]);
        }), 128))]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "success",
          round: "",
          onClick: confirm
        }, {
          default: _withCtx(() => [_createTextVNode("选好了")]),
          _: 1
        })])])])]),
        _: 1
      });
    };
  }
};