// src/stores/animationStore.js
import { defineStore } from 'pinia'

export const useAnimationStore = defineStore('animation', {
  state: () => ({
    highlightUnselected: false,
  }),
  actions: {
    triggerHighlight() {
      this.highlightUnselected = true
      setTimeout(() => {
        this.highlightUnselected = false
      }, 1500) // 动画持续时间
    },
  },
})