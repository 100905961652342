import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d09444c4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "loading-page"
};
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useOrderStore } from '@/stores/orderStore';
import { useErrorStore } from '@/stores/errorStore';

import { ElLoading } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/loading/style/css';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const orderStore = useOrderStore();
    const errorStore = useErrorStore();
    onMounted(async () => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      try {
        // 解析 URL 中的 code 参数
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        await orderStore.fetchOrderDetails(code);
        const status = orderStore.orderDetails?.orderStatus;
        console.log("status: ", status);
        if (status == -2) {
          // code 无效
          errorStore.setError('400', '无效的卡密，请检查后重试。');
          router.replace('/error');
        } else if (status === -1) {
          // 订单未创建
          orderStore.setCode(code);
          router.replace('/restaurants');
        } else {
          // 订单已创建
          orderStore.setCode(code);
          router.replace('/details');
        }
      } catch (error) {
        console.error('Failed to fetch order details:', error);
        errorStore.setError('500', '加载订单详情时发生错误，请稍后重试。');
        router.replace('/error');
      } finally {
        loadingInstance.close();
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1);
    };
  }
};